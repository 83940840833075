<script setup lang="ts">
const props = defineProps<{
	label?: string
	icon: string
	severity?: 'primary' | 'secondary' | 'success' | 'error'
	size?: 'small'
	linkTo?: string | object
	linkTarget?: string
}>();
</script>

<template>
	<button
		type="button"
		class="btn-icon"
		:class="[{
			[`btn-icon--${props.severity}`]: props.severity,
			'btn-icon:sm': props.size === 'small',
		}]"
		v-if="!linkTo"
	>
		<Icon :name="props.icon" v-if="props.icon && !$slots.default" />
		<span class="aria:sr" v-if="props.label">{{ props.label }}</span>
		<slot />
	</button>

	<NuxtLink
		class="btn-icon"
		:class="[{
			[`btn-icon--${props.severity}`]: props.severity,
			'btn-icon:sm': props.size === 'small',
		}]"
		:to="props.linkTo"
		:target="props.linkTarget"
		:rel="props.linkTarget ? 'noopener noreferrer' : null"
		v-else
	>
		<Icon :name="props.icon" v-if="props.icon && !$slots.default" />
		<span class="aria:sr" v-if="props.label">{{ props.label }}</span>
		<slot />
	</NuxtLink>
</template>
